export enum Method {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  PUT = 'PUT',
}

export interface IHeader {
  name: string;
  value: string;
}
export class HttpClient {

  baseUrl = '';
  headers: any = {};

  constructor (baseUrl = '', headers: any = {}) {
    this.baseUrl = baseUrl;
    // this.baseUrl = process.env.REACT_APP_ENV !== 'production' ?  process.env.REACT_APP_RECORDING_API_URL || '' : baseUrl;
    this.headers = headers;
  }

  public setBaseUrl (baseUrl: string): HttpClient {
    this.baseUrl = baseUrl;
    return this;
  }

  public setHeader (name: string, value: string): HttpClient {
    this.headers[name] = value;
    return this;
  }

  public async request (method: Method, url: string, parameters: any = {}): Promise<any> {
    const requestParameters: any = {
      method,
      headers: this.headers,
      credentials: 'include',
    };
    console.log(`${this.baseUrl}${url}`);
    console.log( process.env);
    Object.assign(requestParameters, parameters);
    return fetch(`${this.baseUrl}${url}`, requestParameters);
  }
}
